import axios from "axios";
import React, { useState } from "react";
import Header from "../Header";

function Setting() {

  const [password, setPassword] = useState("");
  const [confirmPswd, setConfirmPswd] = useState("");

const uid=localStorage.getItem("user")
console.log("uid",uid);
const userData = JSON.parse(uid);
console.log("userData",userData.uid);
const userName=userData.userName

const handlePassword = ({ target }) => setPassword(target.value);
  const handleConfirmPswd = ({ target }) => setConfirmPswd(target.value);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = {
        userName,
        password,
        confirmPswd,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}user/change-password`,
        data
      );
      console.log("res", response);
      if (response) {
        alert("Password Changed")
      }
    } catch (error) {
      console.log(error);
      alert('Password not Matched')
    }
  };

  return (
   <div>
        <Header />
      <div className="form-main">
        <div className="login-body">
          <div className="login-page">
            <div className="form">
              <h2 className="text-success mb-3">
                <b>Change Password</b>
              </h2>
              <form className="login-form">
                <input type="password" placeholder="New Password"  onChange={handlePassword} required/>
                <input type="password" placeholder="Confirm Password" onChange={handleConfirmPswd} required/>
                <button type="submit" onClick={handleSubmit}>Change Password</button>
              </form>
            </div>
          </div>
        </div>
      </div>
   </div>
  );
}

export default Setting;
