import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Button, Pagination } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function MaterialAlert() {
  const [data, setData] = useState([]);
  const [paint,setPaint] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchData = async (search = "", page = 1) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}alert/get-alert`
      );
      setData(res.data.alert);
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchPaint = () => {
  //   try {
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}paintproject/get-paint-project-2`)
  //       .then((res) => {
  //         console.log("res",res);
  //         // setPaint(res.data.paintProjects);
  //       });
  //   } catch (error) { 
  //     console.log(error);
  //   }
  // };

  // console.log("paint",paint);

  useEffect(() => {
    fetchData();
    // fetchPaint()
  }, [currentPage]);

  // Calculate the index range to display based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div>
      <Header />
      <div className="container mt-2">
        <h3>MATERIAL ALERTS</h3>
        <div className="float-end  me-5">
          <Link to={""}>
            <Button variant="danger" size="sm" className="ms-2">
              Topology Alerts
            </Button>
          </Link>
          <Link to={""}>
            <Button variant="danger" size="sm" className="ms-2">
              Bright Project Alerts
            </Button>
          </Link>
        </div>
        <div className="p-4 mt-4 bg-dark table-view">
          <div className="paint-list mt-5">
            <table className="paint-inventory-table">
              <tbody>
                <tr>
                  <th style={{ width: "10%" }}>Date</th>
                  <th style={{ width: "15%" }}>Material Name</th>
                  <th style={{ width: "10%" }}>Material Type</th>
                  <th style={{ width: "5%" }}>Quantity left</th>
                  <th style={{ width: "10%" }}>Responded On</th>
                </tr>
                {currentItems.map((item) => (
                  <tr key={item._id}>
                    <td>{item.createdAt.split("T")[0]}</td>
                    <td>{item.paintName}</td>
                    <td>Paint</td>
                    <td>{item.qtyLeft}</td>
                    <td>{item.responded ? item.responded : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination className="mt-1">
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default MaterialAlert;
