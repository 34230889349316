import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "../Header";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

function PaintProjects() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [itemToDelete, setItemToDelete] = useState();
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedMaterialType, setSelectedMaterialType] = useState("");
  const [selectedOrderFrom, setSelectedOrderFrom] = useState("");
  const [selectedOrderTo, setSelectedOrderTo] = useState("");
  const [createdAt, setStartDate] = useState("");
  const [orderFrom, setOrderFrom] = useState("");
  const [projectName, setProjectName] = useState("");
  const [orderTo, setOrderTo] = useState("");
  const [endDate, setEndDate] = useState("");



  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = (id) => {
    setItemToDelete(id);
    handleShow();
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleOrderFrom = (event) => {
    setOrderFrom(event.target.value);
  };

  const handleProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const handleOrderTo = (event) => {
    setOrderTo(event.target.value);
  };

  const handleAdd = () => {
    navigate("/add-paint-projects/" + orderFrom + "/" + projectName + "/" + orderTo);
  };

  const handlePrint = () => {
    navigate("/print-paint-projects",{ state : {data} });
  };


  const resetData = () => {
    setSelectedProjectName("");
    setSelectedMaterialType("");
    setSelectedOrderFrom("");
    setSelectedOrderTo("");
    setStartDate("");
    setEndDate("");
    fetchData();
  };

  const handleDeleteItem = async (id) => {
    const result = await axios.delete(
      `${process.env.REACT_APP_API_URL}paintproject/remove-paint-project/${id}`
    );
    fetchData();
    handleClose();
  };

  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}paintproject/get-paint-project?projectName=${selectedProjectName}&materialType=${selectedMaterialType}&orderFrom=${selectedOrderFrom}&createdAt=${createdAt}&orderTo=${selectedOrderTo}&endDate=${endDate}`;
      const paintProjectsResponse = await axios.get(url);
      const paintProjectsData = paintProjectsResponse.data.project;
      const updatedPaintProjectsData = await Promise.all(
        paintProjectsData.map(async (item) => {
          const materialTypeResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}inventory/edit-paint-material/${item.materialType}`
          );
          const materialTypeData = materialTypeResponse.data.inventory;

          // Fetch data for each log entry
          const updatedDailyLog = await Promise.all(
            item.dailyLog.map(async (logEntry) => {
              const paintResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}inventory/edit-paint/${logEntry.paint}`
              );
              const paintData = paintResponse.data.inventory;

              return {
                ...logEntry,
                paint: paintData,
              };
            })
          );

          return {
            ...item,
            materialType: materialTypeData,
            dailyLog: updatedDailyLog,
          };
        })
      );
      setData(updatedPaintProjectsData);
    } catch (error) {
      console.log(error);
    }
  };







  console.log("data", data);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      console.log(userData);
      setUser(userData);
      if (
        userData.adminType === "admin" ||
        userData.adminType === "office" ||
        userData.adminType === "employee" ||
        userData.adminType === "assignEmployee"
      ) {
        console.log("user loged");
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);


  const calculateTotalPieces = ()=>{
    // let totalPieces = 0;
    // data.forEach(item => {
    //   totalPieces += parseInt(item.pieces, 10);
    // });
    // return totalPieces
    return "NaN"
  }

  const calculateTotalSqFeet =() => {
    // let totalSqFeet = 0;
    // data.forEach(item => {
    //   totalSqFeet += parseFloat(
    //     (
    //       (item.pieceSign === "meter"
    //         ? item.pieceLength * 3.28084
    //         : item.pieceSign === "inch"
    //         ? item.pieceLength * 0.083333336
    //         : item.pieceSign === "centimeter"
    //         ? item.pieceLength * 0.0328084
    //         : item.pieceSign === "millimeter"
    //         ? item.pieceLength * 0.00328084
    //         : item.pieceLength) *
    //       item.materialType.perimeter * 
    //       item.pieces
    //     ).toFixed(5)
    //   )
    // });
    // return totalSqFeet.toFixed(3)
    return "NaN"

  }
    
  const calculateTotalCost = () => {
    // let totalCost = 0;
    // data.forEach(item => {
    //   if (item.dailyLog?.length === 0) {
    //     totalCost += parseFloat(
    //       (
    //         (item.pieceSign === "meter"
    //           ? item.pieceLength * 3.28084
    //           : item.pieceSign === "inch"
    //           ? item.pieceLength * 0.083333336
    //           : item.pieceSign === "centimeter"
    //           ? item.pieceLength * 0.0328084
    //           : item.pieceSign === "millimeter"
    //           ? item.pieceLength * 0.00328084
    //           : item.pieceLength) *
    //         item.materialType.perimeter*
    //         item.materialType.materialPrice *
    //         item.pieces
    //       ).toFixed(5)
    //     );
    //   } else {
    //     totalCost += item.dailyLog.reduce((sum, logEntry) => {
    //       if (logEntry.paint.price > 200) {
    //         return sum + parseFloat(
    //           (
    //             (item.pieceSign === "meter"
    //               ? item.pieceLength * 3.28084
    //               : item.pieceSign === "inch"
    //               ? item.pieceLength * 0.083333336
    //               : item.pieceSign === "centimeter"
    //               ? item.pieceLength * 0.0328084
    //               : item.pieceSign === "millimeter"
    //               ? item.pieceLength * 0.00328084
    //               : item.pieceLength) *
    //             item.materialType.perimeter *
    //             item.materialType.materialPrice *
    //             item.pieces +
    //             item.materialType.perimeter *
    //             item.pieces *
    //             (item.pieceSign === "meter"
    //               ? item.pieceLength * 3.28084
    //               : item.pieceSign === "inch"
    //               ? item.pieceLength * 0.083333336
    //               : item.pieceSign === "centimeter"
    //               ? item.pieceLength * 0.0328084
    //               : item.pieceSign === "millimeter"
    //               ? item.pieceLength * 0.00328084
    //               : item.pieceLength) * 2
    //           ).toFixed(5)
    //         );
    //       } else {
    //         return sum;
    //       }
    //     }, 0);
    //   }
    // });
    // return totalCost.toFixed(3)
    return "NaN"

  }
  

  useEffect(() => {
    fetchData();    
  }, []);

  // useLayoutEffect(() => {
  //   fetchData();    
  // }, [])

  return (
    <div>
      <Header />
      <div className="container mt-2">
        <h3>PAINTING PROJECTS</h3>
        <div className="float-end  me-5">
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={handleShow2}
          >
            Add Painting Project
          </Button>
        </div>
        <div
          className="p-4 mt-4 bg-dark"
          style={{ height: "auto", borderRadius: "10px" }}
        >
          <div className="mb-4">
            <Row>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Name</Form.Label>
                  <Form.Select
                    value={selectedProjectName}
                    onChange={(e) => setSelectedProjectName(e.target.value)}
                  >
                    <option>Select</option>
                    {[...new Set(data.map((item) => item.projectName))].map(
                      (projectName) => (
                        <option key={projectName} value={projectName}>
                          {projectName}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Material Type</Form.Label>
                  <Form.Select
                    value={selectedMaterialType}
                    onChange={(e) => setSelectedMaterialType(e.target.value)}
                  >
                    <option>Select</option>
                    {[
                      ...new Map(
                        data.map((item) => [
                          item.materialType.materialType,
                          item.materialType,
                        ])
                      ).values(),
                    ].map((materialType) => (
                      <option key={materialType._id} value={materialType._id}>
                        {materialType.materialType}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Order From</Form.Label>
                  <Form.Select
                    value={selectedOrderFrom}
                    onChange={(e) => setSelectedOrderFrom(e.target.value)}
                  >
                    <option>Select</option>
                    {[...new Set(data.map((item) => item.orderFrom))].map(
                      (orderFrom) => (
                        <option key={orderFrom} value={orderFrom}>
                          {orderFrom}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Order To</Form.Label>
                  <Form.Select
                    value={selectedOrderTo}
                    onChange={(e) => setSelectedOrderTo(e.target.value)}
                  >
                    <option>Select</option>
                    {[...new Set(data.map((item) => item.orderTo))].map(
                      (orderTo) => (
                        <option key={orderTo} value={orderTo}>
                          {orderTo}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} sm={6} md={4} lg={4}>
                <Form.Group as={Col} className="d-flex">
                  <Form.Label className="text-light me-5">
                    Start Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    name="paintCode"
                    value={createdAt}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} md={4} lg={4}>
                <Form.Group as={Col} className="d-flex">
                  <Form.Label className="text-light me-5"> End Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    name="paintCode"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Form.Group as={Col}>
                <Button
                  variant="success"
                  size="lg"
                  className=" mt-1"
                  onClick={fetchData}
                >
                  &nbsp;&nbsp;Search&nbsp;&nbsp;
                </Button>
                <Button
                  variant="warning"
                  size="lg"
                  className=" mt-1 ms-2"
                  onClick={handlePrint}
                >
                  &nbsp;&nbsp;Print&nbsp;&nbsp;
                </Button>
                <Button
                  variant="secondary"
                  size="lg"
                  className=" mt-1 ms-2"
                  onClick={resetData}
                >
                  &nbsp;&nbsp;Reset&nbsp;&nbsp;
                </Button>
              </Form.Group>
            </Row>
          </div>
          <div className="paint-project-math">
            <div>
              <h6 className="text-white">Total Cost: <b>{calculateTotalCost()?calculateTotalCost():""}</b></h6>

            </div>
            <div>
              <h6 className="text-white">Total number of pieces: <b>{calculateTotalPieces()?calculateTotalPieces():""}</b></h6>

            </div>
            <div>
              <h6 className="text-white"> Total Sq Feet: <b>{calculateTotalSqFeet()?calculateTotalSqFeet():""}</b></h6>
            </div>
          </div>

          <div
            className="paint-list"
            style={{ maxHeight: "380px", overflowY: "auto" }}
          >
            <table className="paint-inventory-table ">
              <tbody>
                <tr>
                  <th style={{ width: "10%" }}>Date</th>
                  <th style={{ width: "15%" }}>Name</th>
                  <th style={{ width: "15%" }}>Order From</th>
                  <th style={{ width: "10%" }}>Order To</th>
                  <th style={{ width: "20%" }}>Type</th>
                  <th style={{ width: "5%" }}>Pcs</th>
                  <th style={{ width: "10%" }}>Sq Ft</th>
                  <th style={{ width: "5%" }}>Length</th>
                  <th style={{ width: "5%" }}>COMPL</th>
                  <th style={{ width: "10%" }}>Tot Cost</th>
                  <th style={{ width: "10%" }}>Expense</th>
                  {user?.adminType === "admin" ||
                  user?.adminType === "office" ? (
                    <th style={{ width: "15%" }}>Actions</th>
                  ) : (
                    ""
                  )}
                </tr>
                {data?.slice(0,100).map((item) => (
                  <tr key={item._id}>
                    <td>{item.createdAt.split("T")[0]}</td>
                    <Link
                      to={
                        "/view-paint-projects/" +
                        item._id +
                        "/" +
                        item.materialType._id
                      }
                      className="text-dark"
                    >
                      <td>{item.projectName}</td>
                    </Link>
                    <td>{item.orderFrom}</td>
                    <td>{item.orderTo}</td>
                    <td>{item.materialType?.materialType}</td>
                    <td>{item.pieces}</td>
                    <td>
                      {(
                        (item.pieceSign === "meter"
                          ? item.pieceLength * 3.28084
                          : item.pieceSign === "inch"
                          ? item.pieceLength * 0.083333336
                          : item.pieceSign === "centimeter"
                          ? item.pieceLength * 0.0328084
                          : item.pieceSign === "millimeter"
                          ? item.pieceLength * 0.00328084
                          : item.pieceLength) *
                        item.materialType.perimeter *
                        item.pieces
                      ).toFixed(3)}
                    </td>
                    <td>{item.pieceLength} / {item.pieceSign}</td>
                    <td>
                      {item.dailyLog.reduce(
                        (total, log) => total + parseInt(log.noPieces),
                        0
                      )}
                    </td>
                    <td>
                      {item.dailyLog?.length === 0
                        ? (
                            (item.pieceSign === "meter"
                              ? item.pieceLength * 3.28084
                              : item.pieceSign === "inch"
                              ? item.pieceLength * 0.083333336
                              : item.pieceSign === "centimeter"
                              ? item.pieceLength * 0.0328084
                              : item.pieceSign === "millimeter"
                              ? item.pieceLength * 0.00328084
                              : item.pieceLength) *
                            item.materialType.perimeter *
                            item.materialType.materialPrice *
                            item.pieces
                          ).toFixed(3)
                        : item.dailyLog.map((pa) =>
                            pa.paint.price > 200
                              ? ((item.pieceSign === "meter"
                                  ? item.pieceLength * 3.28084
                                  : item.pieceSign === "inch"
                                  ? item.pieceLength * 0.083333336
                                  : item.pieceSign === "centimeter"
                                  ? item.pieceLength * 0.0328084
                                  : item.pieceSign === "millimeter"
                                  ? item.pieceLength * 0.00328084
                                  : item.pieceLength) *
                                  item.materialType.perimeter *
                                  item.materialType.materialPrice *
                                  item.pieces +
                                item.materialType.perimeter *
                                  item.pieces *
                                  (item.pieceSign === "meter"
                                  ? item.pieceLength * 3.28084
                                  : item.pieceSign === "inch"
                                  ? item.pieceLength * 0.083333336
                                  : item.pieceSign === "centimeter"
                                  ? item.pieceLength * 0.0328084
                                  : item.pieceSign === "millimeter"
                                  ? item.pieceLength * 0.00328084
                                  : item.pieceLength) *2).toFixed(3)
                              : "0"
                          )}
                    </td>

                    <td>{item.expense ? item.expense : "0"}</td>
                    {user?.adminType === "admin" ? (
                      <td>
                        <Link to={"/edit-paint-projects/" + item._id}>
                          <i className="fa fa-pencil text-warning"></i>
                        </Link>
                        <Link onClick={(e) => handleDelete(item._id)}>
                          <i className="fa fa-trash text-danger ms-3"></i>
                        </Link>
                      </td>
                    ) : (
                      ""
                    )}
                    {user?.adminType === "office" ? (
                      <td>
                        <Link to={"/edit-paint-projects/" + item._id}>
                          <i className="fa fa-pencil text-warning"></i>
                        </Link>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <Footer /> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bright Aluminium</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this item</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => handleDeleteItem(itemToDelete)}
          >
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Project Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4}>
              <Form.Label>
                <b>Order From</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Select
                style={{ border: "1px solid black" }}
                onChange={handleOrderFrom}
              >
                <option disabled selected required>
                  Select
                </option>
                <option value="Bright Ernakulam">Bright Ernakulam</option>
                <option value="Bright Bypass">Bright Bypass</option>
                <option value="Bright Kayamkulam">Bright Kayamkulam</option>
                <option value="Eternia">Eternia</option>
                <option value="Direct">Direct</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={4}>
              <Form.Label>
                <b>Order To</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Select
                style={{ border: "1px solid black" }}
                onChange={handleOrderTo}
              >
                <option disabled selected required>
                  Select
                </option>
                <option value="Vennala">Vennala</option>
                <option value="Edayar">Edayar</option>
                <option value="Thrissur">Thrissur</option>
                <option value="Kannur">Kannur</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={4}>
              <Form.Label>
                <b>Project Name</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                style={{ border: "1px solid black" }}
                onChange={handleProjectName}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleAdd}>
            Add
          </Button>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaintProjects;
