import React from 'react'
import Header from "../Header";
import { useLocation } from "react-router-dom";

function PrintSales() {

    const location = useLocation();
    // Extract data from location state
    const { data } = location.state || {};
    console.log("lo",data);

  return (
<div>
     <Header/>   
     <div
            className="container mt-4 paint-list"
            style={{ maxHeight: "auto", overflowY: "auto" }}
          >
            <table className="paint-inventory-table ">
              <tbody>
                <tr>
                <th style={{ width: "10%" }}>Date</th>
                  <th style={{ width: "15%" }}>Name</th>
                  <th style={{ width: "15%" }}>Order From</th>
                  <th style={{ width: "10%" }}>Order To</th>
                  <th style={{ width: "20%" }}>Material Type</th>
                  <th style={{ width: "15%" }}>New Material Type</th>
                  <th style={{ width: "5%" }}>Pcs</th>
                  <th style={{ width: "5%" }}>Length</th>
                  <th style={{ width: "5%" }}>Stock</th>
                </tr>
                {data?.slice(0,100).map((item) => (
                  <tr key={item._id}>
                   <td>{item.createdAt.split("T")[0]}</td>
                    <td>{item.projectName}</td>
                    <td>{item.orderFrom}</td>
                    <td>{item.orderTo}</td>
                    <td>{item.materialType?.materialType}</td>
                    <td>{item.newMaterialType}</td>

                    <td>{item.pieces}</td>
                    <td>
                      {item.pieceLength} / {item.pieceSign}
                    </td>
                    <td>{item.stock === true ? "yes" : "no"}</td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
    </div>  )
}

export default PrintSales