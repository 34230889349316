import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Button, Col, Pagination, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
function Sales() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [orderFrom, setOrderFrom] = useState("");
  const [projectName, setProjectName] = useState("");
  const [orderTo, setOrderTo] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedMaterialType, setSelectedMaterialType] = useState("");
  const [selectedOrderFrom, setSelectedOrderFrom] = useState("");
  const [selectedOrderTo, setSelectedOrderTo] = useState("");
  const [createdAt, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");



  const [itemToDelete, setItemToDelete] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = (id) => {
    setItemToDelete(id);
    handleShow();
  };

  const handleOrderFrom = (event) => {
    setOrderFrom(event.target.value);
  };

  const handleProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const handleOrderTo = (event) => {
    setOrderTo(event.target.value);
  };

  const handleAdd = () => {
    navigate("/add-sales/" + orderFrom + "/" + projectName + "/" + orderTo);
  };

  const resetData = () => {
    setSelectedProjectName("");
    setSelectedMaterialType("");
    setSelectedOrderFrom("");
    setSelectedOrderTo("");
    setStartDate("");
    setEndDate("");
    fetchData();
  };


  const handlePrint = () => {
    navigate("/print-sales",{ state : {data} });
  };

  const handleDeleteItem = async (id) => {
    const result = axios.delete(
      `${process.env.REACT_APP_API_URL}sale/remove-sale/${id}`
    );
    fetchData();
    handleClose();
  };

  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}sale/get-sale?projectName=${selectedProjectName}&materialType=${selectedMaterialType}&orderFrom=${selectedOrderFrom}&createdAt=${createdAt}&orderTo=${selectedOrderTo}&endDate=${endDate}`;
      const paintProjectsResponse = await axios.get(url);
      const paintProjectsData = paintProjectsResponse.data.project;
      const updatedPaintProjectsData = await Promise.all(
        paintProjectsData.map(async (item) => {
          const materialTypeResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}inventory/edit-paint-material/${item.materialType}`
          );
          const materialTypeData = materialTypeResponse.data.inventory;

          // Fetch data for each log entry
          const updatedDailyLog = await Promise.all(
            item.dailyLog.map(async (logEntry) => {
              const paintResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}inventory/edit-paint/${logEntry.paint}`
              );
              const paintData = paintResponse.data.inventory;

              return {
                ...logEntry,
                paint: paintData,
              };
            })
          );

          return {
            ...item,
            materialType: materialTypeData,
            dailyLog: updatedDailyLog,
          };
        })
      );
      setData(updatedPaintProjectsData);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("data", data);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      console.log(userData);
      setUser(userData);
      if (
        userData.adminType === "admin" ||
        userData.adminType === "office" ||
        userData.adminType === "employee" ||
        userData.adminType === "assignEmployee"
      ) {
        console.log("user loged");
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);

  // Calculate the index range to display based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <div>
      <Header />
      <div className="container mt-2">
        <h3>SALES</h3>
        <div className="float-end  me-5">
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={handleShow2}
          >
            Add
          </Button>
        </div>
        <div className="p-4 mt-4 bg-dark table-view">
        <div className="mb-4">
            <Row>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Name</Form.Label>
                  <Form.Select
                    value={selectedProjectName}
                    onChange={(e) => setSelectedProjectName(e.target.value)}
                  >
                    <option>Select</option>
                    {[...new Set(data.map((item) => item.projectName))].map(
                      (projectName) => (
                        <option key={projectName} value={projectName}>
                          {projectName}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Material Type</Form.Label>
                  <Form.Select
                    value={selectedMaterialType}
                    onChange={(e) => setSelectedMaterialType(e.target.value)}
                  >
                    <option>Select</option>
                    {[
                      ...new Map(
                        data.map((item) => [
                          item.materialType.materialType,
                          item.materialType,
                        ])
                      ).values(),
                    ].map((materialType) => (
                      <option key={materialType._id} value={materialType._id}>
                        {materialType.materialType}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Order From</Form.Label>
                  <Form.Select
                    value={selectedOrderFrom}
                    onChange={(e) => setSelectedOrderFrom(e.target.value)}
                  >
                    <option>Select</option>
                    {[...new Set(data.map((item) => item.orderFrom))].map(
                      (orderFrom) => (
                        <option key={orderFrom} value={orderFrom}>
                          {orderFrom}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group as={Col}>
                  <Form.Label className="text-light">Order To</Form.Label>
                  <Form.Select
                    value={selectedOrderTo}
                    onChange={(e) => setSelectedOrderTo(e.target.value)}
                  >
                    <option>Select</option>
                    {[...new Set(data.map((item) => item.orderTo))].map(
                      (orderTo) => (
                        <option key={orderTo} value={orderTo}>
                          {orderTo}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} sm={6} md={4} lg={4}>
                <Form.Group as={Col} className="d-flex">
                  <Form.Label className="text-light me-5">
                    Start Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    name="paintCode"
                    value={createdAt}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} md={4} lg={4}>
                <Form.Group as={Col} className="d-flex">
                  <Form.Label className="text-light me-5"> End Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    name="paintCode"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Form.Group as={Col}>
                <Button
                  variant="success"
                  size="lg"
                  className=" mt-1"
                  onClick={fetchData}
                >
                  &nbsp;&nbsp;Search&nbsp;&nbsp;
                </Button>
                <Button
                  variant="warning"
                  size="lg"
                  className=" mt-1 ms-2"
                  onClick={handlePrint}
                >
                  &nbsp;&nbsp;Print&nbsp;&nbsp;
                </Button>
                <Button
                  variant="secondary"
                  size="lg"
                  className=" mt-1 ms-2"
                  onClick={resetData}
                >
                  &nbsp;&nbsp;Reset&nbsp;&nbsp;
                </Button>
              </Form.Group>
            </Row>
          </div>
          <div className="paint-list">
            <table className="paint-inventory-table ">
              <tbody>
                <tr>
                  <th style={{ width: "10%" }}>Date</th>
                  <th style={{ width: "15%" }}>Name</th>
                  <th style={{ width: "15%" }}>Order From</th>
                  <th style={{ width: "10%" }}>Order To</th>
                  <th style={{ width: "20%" }}>Material Type</th>
                  <th style={{ width: "15%" }}>New Material Type</th>
                  <th style={{ width: "5%" }}>Pcs</th>
                  <th style={{ width: "5%" }}>Length</th>
                  <th style={{ width: "5%" }}>Stock</th>
                  {user?.adminType === "admin" ||
                  user?.adminType === "office" ? (
                    <th style={{ width: "10%" }}>Actions</th>
                  ) : (
                    ""
                  )}{" "}
                </tr>
                {currentItems.map((item) => (
                  <tr key={item._id}>
                    <td>{item.createdAt.split("T")[0]}</td>
                    <td>{item.projectName}</td>
                    <td>{item.orderFrom}</td>
                    <td>{item.orderTo}</td>
                    <td>{item.materialType?.materialType}</td>
                    <td>{item.newMaterialType}</td>

                    <td>{item.pieces}</td>
                    <td>
                      {item.pieceLength} / {item.pieceSign}
                    </td>
                    <td>{item.stock === true ? "yes" : "no"}</td>
                    {user?.adminType === "admin" ? (
                      <td>
                        <Link to={{ pathname: "/edit-sales/" + item._id }}>
                          <i className="fa fa-pencil text-warning"></i>
                        </Link>

                        <Link onClick={(e) => handleDelete(item._id)}>
                          <i className="fa fa-trash text-danger ms-3"></i>
                        </Link>
                      </td>
                    ) : (
                      ""
                    )}
                    {user?.adminType === "office" ? (
                      <td>
                        <Link to={{ pathname: "/edit-sales/" + item._id }}>
                          <i className="fa fa-pencil text-warning"></i>
                        </Link>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination className="mt-1">
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
      {/* <Footer /> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bright Aluminium</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this item</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => handleDeleteItem(itemToDelete)}
          >
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Project Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-5">
            <Col xs={4}>
              <Form.Label>
                <b>Order From</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                style={{ border: "1px solid black" }}
                onChange={handleOrderFrom}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={4}>
              <Form.Label>
                <b>Order TO</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                style={{ border: "1px solid black" }}
                onChange={handleOrderTo}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={4}>
              <Form.Label>
                <b>Project Name</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="text"
                style={{ border: "1px solid black" }}
                onChange={handleProjectName}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleAdd}>
            Add
          </Button>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Sales;
