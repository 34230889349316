import React, { useEffect, useState } from "react";
import Header from "../Header";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Footer from "../Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function EditSales() {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [orderFrom, setOrderFrom] = useState(params.ofrom || "");
    const [orderTo, setOrderTo] = useState(params.oto || "");
    const [materialType, setMaterialType] = useState("");
    const [newMaterialType, setNewMaterialType] = useState("");
    const [projectName, setProjectName] = useState(params.pname || "");
    const [pieces, setPieces] = useState("");
    const [pieceLength, setPieceLength] = useState("");
    const [pieceSign, setPieceSign] = useState("");
    const [stock,setStock] = useState('')
  
  
    const handleOrderFrom = ({ target }) => setOrderFrom(target.value);
    const handleNewMaterialType = ({ target }) =>setNewMaterialType(target.value);
    const handleOrderTo = ({ target }) => setOrderTo(target.value);
    const handleMaterialType = ({ target }) => setMaterialType(target.value);
    const handleProjectName = ({ target }) => setProjectName(target.value);
    const handlePieces = ({ target }) => setPieces(target.value);
    const handlePieceLength = ({ target }) => setPieceLength(target.value);
    const handlePieceSign = ({ target }) => setPieceSign(target.value);
  
    const handleStockChange = (value) => setStock(value);
  
    console.log("materialType", materialType);
    console.log("stockin", stock);
  //   console.log("stockout", StockOut);
  
  
    const handleSubmit = async (e) => {
      try {
        e.preventDefault();
        const data = {
          orderFrom,
          orderTo,
          materialType,
          newMaterialType,
          projectName,
          pieces,
          pieceLength,
          pieceSign,
          stock
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}sale/update-sale/${params.id}`,
          data
        );
        console.log("res", response);
        if (response) {
            navigate("/sales");
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const fecthData = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}inventory/get-paint-material`)
        .then((res) => {
          console.log(res.data.paintMaterial);
          setData(res.data.paintMaterial);
        });
    };

    useEffect(() => {
        console.log("params.id", params.id);
        axios
          .get(
            `${process.env.REACT_APP_API_URL}sale/edit-sale/${params.id}`
          )
          .then((res) => {
            console.log("response", res.data.project);
            setMaterialType(res.data.project.materialType);
            setOrderFrom(res.data.project.orderFrom);
            setOrderTo(res.data.project.orderTo);
            setProjectName(res.data.project.projectName);
            setPieces(res.data.project.pieces);
            setPieceLength(res.data.project.pieceLength);
            setPieceSign(res.data.project.pieceSign);
            setStock(res.data.project.stock)
            setNewMaterialType(res.data.project.newMaterialType)
          })
          .catch((err) => console.error(err));
      }, []);
  
    useEffect(() => {
      fecthData();
    }, []);
  
    const [searchTerm, setSearchTerm] = useState("");
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
    // Filter options based on search term
    const filteredData = data.filter((item) =>
      item.materialType.toLowerCase().includes(searchTerm.toLowerCase())
    );
  return (
    <div>
    <Header />
    <div className="container mt-3">
      <h3>ADD SALES</h3>
      <div className="p-5 mt-4 bg-dark add-table">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md>
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Order From"
                >
                  <Form.Control
                    type="text"
                    placeholder="Order From"
                    id="orderFrom"
                    value={orderFrom}
                    onChange={handleOrderFrom}
                    required
                  />
                </FloatingLabel>
              </Col>
            </Col>
            <Col md>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Project Name"
              >
                <Form.Control
                  type="text"
                  placeholder="Paint Name"
                  id="projectName"
                  value={projectName}
                  onChange={handleProjectName}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md>
              <Form.Control
                type="text"
                placeholder="Search material type"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Form.Select
                size="lg"
                id="materialType"
                onChange={handleMaterialType}
                value={materialType}
              >
                <option disabled selected required>
                  Material Type Select
                </option>
                {filteredData.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.materialType}
                  </option>
                ))}
              </Form.Select>
            </Col>
            {materialType === "664c321debc9c736762f1d19" ? (
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="New Material Type"
                >
                  <Form.Control
                    type="text"
                    placeholder="Paint Name"
                    id="pieces"
                    onChange={handleNewMaterialType}
                    value={newMaterialType}
                    required
                  />
                </FloatingLabel>
              </Col>
            ) : (
              <Col></Col>
            )}
          </Row>
          <Row className="mt-5">
            <Col md>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Length of Piece"
              >
                <Form.Control
                  type="text"
                  placeholder="Length of Piece"
                  id="pieceLength"
                  onChange={handlePieceLength}
                  value={pieceLength}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="No:of Pieces"
              >
                <Form.Control
                  type="text"
                  placeholder="Paint Name"
                  id="pieces"
                  onChange={handlePieces}
                  value={pieces}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md>
              <Form.Select
                size="lg"
                id="pieceSign"
                onChange={handlePieceSign}
                value={pieceSign}
              >
                <option disabled selected required>
                  Select
                </option>
                <option value="feet">feet</option>
                <option value="inch">inch</option>
                <option value="meter">meter</option>
                <option value="centimeter">centimeter</option>
                <option value="millimeter">millimeter</option>
              </Form.Select>
            </Col>

            <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Order To"
                >
                  <Form.Control
                    type="text"
                    placeholder="Order From"
                    id="orderFrom"
                    value={orderTo}
                    onChange={handleOrderTo}
                    required
                  />
                </FloatingLabel>
              </Col>

            <Col md>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                  style={{ width: "25px", height: "25px" }}
                  checked={stock === true}
                  onChange={() => handleStockChange(true)}
                />
                <label
                  className="form-check-label text-white"
                  htmlFor="inlineRadio1"
                >
                  <h4 className="ms-1"> Stock In</h4>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                  style={{ width: "25px", height: "25px" }}
                  checked={stock === false}
                  onChange={() => handleStockChange(false)}
                />
                <label
                  className="form-check-label text-white"
                  htmlFor="inlineRadio2"
                >
                  <h4 className="ms-1"> Stock Out</h4>
                </label>
              </div>
            </Col>
          </Row>
          <div className="mt-4">
            <Button variant="success" size="lg" type="submit">
              &nbsp;&nbsp;Save&nbsp;&nbsp;
            </Button>
            <Link to={"/sales"}>
              <Button className="ms-4" variant="warning" size="lg">
                Cancel
              </Button>
            </Link>
          </div>
        </form>
      </div>
    </div>
    <Footer />
  </div>  )
}

export default EditSales