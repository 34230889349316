import React, { useEffect, useState } from "react";
import Header from "../Header";
import axios from "axios";
import { useLocation } from "react-router-dom";



function PrintPaintProject() {

  const location = useLocation();
  // Extract data from location state
  const { data } = location.state || {};
  console.log("lo",data);
  //   const [data, setData] = useState([]);



  //   const fetchData = async () => {
  //       try {
  //           const url = `${process.env.REACT_APP_API_URL}paintproject/get-paint-project`;
  //           const paintProjectsResponse = await axios.get(url);
  //         const paintProjectsData = paintProjectsResponse.data.project;
  //         const updatedPaintProjectsData = await Promise.all(
  //           paintProjectsData.map(async (item) => {
  //             const materialTypeResponse = await axios.get(
  //               `${process.env.REACT_APP_API_URL}inventory/edit-paint-material/${item.materialType}`
  //             );
  //             const materialTypeData = materialTypeResponse.data.inventory;
    
  //             // Fetch data for each log entry
  //             const updatedDailyLog = await Promise.all(
  //               item.dailyLog.map(async (logEntry) => {
  //                 const paintResponse = await axios.get(
  //                   `${process.env.REACT_APP_API_URL}inventory/edit-paint/${logEntry.paint}`
  //                 );
  //                 const paintData = paintResponse.data.inventory;
    
  //                 return {
  //                   ...logEntry,
  //                   paint: paintData,
  //                 };
  //               })
  //             );
    
  //             return {
  //               ...item,
  //               materialType: materialTypeData,
  //               dailyLog: updatedDailyLog,
  //             };
  //           })
  //         );
  //         setData(updatedPaintProjectsData);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };
      
  // useEffect(() => {
  //   fetchData();
  // }, []);
    
  return (
    <div>
     <Header/>   
     <div
            className="container mt-4 paint-list"
            style={{ maxHeight: "auto", overflowY: "auto" }}
          >
            <table className="paint-inventory-table ">
              <tbody>
                <tr>
                  <th style={{ width: "10%" }}>Date</th>
                  <th style={{ width: "20%" }}>Name</th>
                  <th style={{ width: "15%" }}>Order From</th>
                  <th style={{ width: "15%" }}>Order To</th>
                  <th style={{ width: "20%" }}>Type</th>
                  <th style={{ width: "5%" }}>Pcs</th>
                  <th style={{ width: "10%" }}>Sq Ft</th>
                  <th style={{ width: "5%" }}>Length</th>
                  <th style={{ width: "5%" }}>COMPL</th>
                  <th style={{ width: "10%" }}>Tot Cost</th>
                  <th style={{ width: "5%" }}>Expense</th>
                </tr>
                {data?.slice(0,100).map((item) => (
                  <tr key={item._id}>
                    <td>{item.createdAt.split("T")[0]}</td>
                    <td>{item.projectName}</td>
                    <td>{item.orderFrom}</td>
                    <td>{item.orderTo}</td>
                    <td>{item.materialType.materialType}</td>
                    <td>{item.pieces}</td>
                    <td>
                      {(
                        (item.pieceSign === "meter"
                          ? item.pieceLength * 3.28084
                          : item.pieceSign === "inch"
                          ? item.pieceLength * 0.083333336
                          : item.pieceSign === "centimeter"
                          ? item.pieceLength * 0.0328084
                          : item.pieceSign === "millimeter"
                          ? item.pieceLength * 0.00328084
                          : item.pieceLength) *
                        item.materialType.perimeter *
                        item.pieces
                      ).toFixed(5)}
                    </td>
                    <td>{item.pieceLength} / {item.pieceSign}</td>
                    <td>
                      {item.dailyLog.reduce(
                        (total, log) => total + parseInt(log.noPieces),
                        0
                      )}
                    </td>
                    <td>
                      {item.dailyLog?.length === 0
                        ? (
                            (item.pieceSign === "meter"
                              ? item.pieceLength * 3.28084
                              : item.pieceSign === "inch"
                              ? item.pieceLength * 0.083333336
                              : item.pieceSign === "centimeter"
                              ? item.pieceLength * 0.0328084
                              : item.pieceSign === "millimeter"
                              ? item.pieceLength * 0.00328084
                              : item.pieceLength) *
                            item.materialType.perimeter *
                            item.materialType.materialPrice *
                            item.pieces
                          ).toFixed(5)
                        : item.dailyLog.map((pa) =>
                            pa.paint.price > 200
                              ? ((item.pieceSign === "meter"
                                  ? item.pieceLength * 3.28084
                                  : item.pieceSign === "inch"
                                  ? item.pieceLength * 0.083333336
                                  : item.pieceSign === "centimeter"
                                  ? item.pieceLength * 0.0328084
                                  : item.pieceSign === "millimeter"
                                  ? item.pieceLength * 0.00328084
                                  : item.pieceLength) *
                                  item.materialType.perimeter *
                                  item.materialType.materialPrice *
                                  item.pieces +
                                item.materialType.perimeter *
                                  item.pieces *
                                  (item.pieceSign === "meter"
                                  ? item.pieceLength * 3.28084
                                  : item.pieceSign === "inch"
                                  ? item.pieceLength * 0.083333336
                                  : item.pieceSign === "centimeter"
                                  ? item.pieceLength * 0.0328084
                                  : item.pieceSign === "millimeter"
                                  ? item.pieceLength * 0.00328084
                                  : item.pieceLength) *2).toFixed(5)
                              : "0"
                          )}
                    </td>

                    <td>{item.expense ? item.expense : "0"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
    </div>
  )
}

export default PrintPaintProject